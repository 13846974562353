<template>
    <div class="pl-2 pr-2">
      <div class="row">
        <div class="col-md-12">
          <!-- <px-card>
            <template slot="headerCustom"> -->
              <b-row>
              <b-col cols="5">
              <h4 class="custome-title"> Latest Events </h4>
              </b-col>
              <b-col class="text-right">
                <b-form-group
                  label-cols="6"
                  label="Per page"
                  class="text-right custom-font-20 text-secondary border border-dark pr-0 pl-1 b-r-8 d-inline-block"
                  >
                  <b-form-select
                    class="custom-font-22 text-dark border-0 b-r-8"
                    v-model="perPage"
                    :options="pageOptions"
                    @change="getEvent()"
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
              </b-row>
            <!-- </template> -->
            <div slot="headerAction"></div>
            <div slot="" class="">
              <div class="col-md-12" v-if="loading">
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div>
              <div v-else class="table-responsive datatable-vue text-left custom-font-18">
                <b-table
                  class="events-table"
                  show-empty
                  stacked="md"
                  :items="event_data"
                  :filter="filter"
                  @filtered="onFiltered"
                  :fields="tablefields"
                  :per-page="perPage"
                >
                  <!-- <template #table-caption>Ongoing Events.</template> -->
                  <template #cell(mName)="data">
                   <span class="cust_txt" v-if="monitor_data.name">{{ monitor_data.name }}</span>
                    <!-- <span v-if="monitor_data.filter((t)=> t.value == data.item.mId)[0]">
                    {{monitor_data.filter((t)=> t.value == data.item.mId)[0].text}}</span> -->
                    <span class="cust_txt" v-else>{{data.item.mName}}</span>
                  </template>
                  <!-- <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template> -->
                  <template #cell(event)="data">
                    <span
                      v-if="data.item.event == 'start'"
                      class="badge badge-flat badge-primary"
                    >
                    Started</span
                    >
                    <span
                      v-if="data.item.event == 'pause'"
                      class="badge badge-flat badge-secondary text-white"
                    >
                    Paused </span
                    >
                    <span
                      v-if="data.item.event == 'down'"
                      class="badge badge-flat badge-danger"
                    >
                    Down </span
                    >
                    <span
                      v-if="data.item.event == 'up'"
                      class="badge badge-flat badge-success"
                    >
                    UP </span
                    >
                  </template>
                  <template #cell(createdAt)="data">
                    <span class="">{{changeDateFormate(data.item.createdAt)}}</span>
                  </template>
                </b-table>
              </div>
              <b-col md="12" class="text-right mt-3 p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="getEvent('prev')">&laquo; Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="event_data.length >= 1" @click="getEvent('next')">Next &raquo;</a>
                </div>
              </b-col>
            </div>
          <!-- </px-card> -->
        </div>
      </div>
    </div>
</template>
  
  
<script>
import moment from 'moment'
import DashboardController from "../../services/dashboardController";
export default {
  name: "Event",
  props:{monitor_id:String,monitor_data:Object,dateRanges:Object,update_filter:Number},
  data() {
    return {
      event_data: [],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50],
      loading: false,
      tablefields: [
        { key: "event", label: "Event", sortable: false },
        { key: "createdAt", label: "Started At", sortable: false },
      ],
    };
  },
  mounted() {
    this.getEvent();
    if(Object.keys(this.monitor_data).length == 0){
      this.tablefields.unshift({ key: "mName", label: "Name", sortable: true })
    }
  },
  watch:{
    async monitor_id(){
      this.currentPage = 1
      await this.getEvent()
    },
    async update_filter(){
      await this.getEvent('',this.dateRanges)
    }
  },
  methods: {
    changeDateFormate(d){
        return moment(d).format('D/M/YYYY, hh:mm A')
    },
    async getEvent(txt,dateRange) {
      if(txt == 'next'){this.currentPage ++}
          if(txt == 'prev'){
            if(this.currentPage != 1){
            this.currentPage --
            }
          }
      var payload = '?currentPage='+this.currentPage+'&pageSize='+this.perPage
      if(dateRange){
        dateRange.startDate = dateRange.startDate.toISOString()
        dateRange.endDate = dateRange.endDate.toISOString()
      }
      if(dateRange){
         payload = '?currentPage='+this.currentPage+'&pageSize='+this.perPage+'&sdate='+dateRange.startDate+'&edate='+dateRange.endDate
       }
      if(this.monitor_id && this.monitor_id != null){
         payload = '?mid='+this.monitor_id+'&currentPage='+this.currentPage+'&pageSize='+this.perPage
         if(dateRange){
         payload = '?mid='+this.monitor_id+'&currentPage='+this.currentPage+'&pageSize='+this.perPage+'&sdate='+dateRange.startDate+'&edate='+dateRange.endDate
        }
      }
      this.loading = true;
      let response = await DashboardController.getEvents(payload);
      if (response.result) {
        this.event_data = []
        this.event_data = response.data;
        this.totalRows = this.event_data.length;
      }
      this.loading = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
};
</script>




<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {background-color: #ddd;cursor: pointer;}

.badge-secondary {
  background-color: gray !important;
}

@media  screen and (max-width: 768px) {
  .custom-font-20 {
    font-size: 0.87rem !important;
  }
  .custom-font-22{
    font-size: 1rem !important;
  }
}
</style>